import styled from 'styled-components';

import { DESKTOP_1024, TABLET_992, TABLET_800, TABLET_768, TABLET_740, MOBILE_460, DESKTOP_1200, MOBILE_380, } from '../globals/sizes';

import apply_bg from '../../images/careers/jpg/applyNow_bg.jpg'
import mapPin from "../../images/careers/svg/map-pin.svg"
import telephone from "../../images/careers/svg/phone-call.svg"
import mail from "../../images/careers/svg/mail.svg"

import { COLORS } from '../globals/colors'; 

export const HeroBackground = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: auto;
    margin-top: 90px;
`

export const HeroContainer = styled.div`
    height: 804px;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    margin-top: 0px;
    overflow: hidden;
    position: relative;

    @media (max-width: ${TABLET_992}px) {
        height: unset;
    }
    @media (max-width: ${MOBILE_460}px) {
        height: unset;
    }

`;

export const HeroPicture = styled.picture`
  & > img, 
  & > source {
    width: 100%;
    height: 804px;
    object-fit: cover;
    object-position: right;
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: ${TABLET_992}px) {
        width: 130vw;
        max-width: unset;
        height: unset;
    }
  }
`

export const InternalWrapper = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    margin-left: 45px;

    @media (max-width: 1200px) {
        width: 95%;
    }
    @media (max-width: 992px) {
        background: white;
        max-width: 100%;
        width: 100%;
        flex: 1;
        z-index: 5;
    }
`


export const TextDiv = styled.div`

    @media (max-width: ${TABLET_992}px) {
        text-align: left;
        margin-top: 75vw;
        margin-left: auto;
        margin-right: auto;
        max-width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
    @media (max-width: ${TABLET_768}px) {
        max-width:90%;
    }
`;

export const Title = styled.h1`
    color: ${COLORS.redTitle};
    font-size: 45px;
    line-height: 50px;
    margin-top: 170px;
    width: 600px;

    & > span {
        white-space: nowrap;
    }

    @media (max-width: ${TABLET_992}px) {
        margin-top: 6vw;
        width: 100%;
    }
    @media (max-width: ${MOBILE_460}px) {
        margin-top: 10vw;
        font-size: 32px;
        line-height: 45px;
    }

`;

export const IntroductionDiv = styled.div`
    color: #404040;
    margin-top: 24px;
    width: 35%;
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;

    @media (max-width: ${TABLET_992}px) {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 30px;
    }
    @media (max-width: ${MOBILE_460}px) {
        line-height: 26px;
        font-weight: 300;
        font-size: 19px;
    }
`;

export const CTAButton = styled.a`
    cursor: pointer;
    background: ${COLORS.redTitle};
    color: white;
    width: fit-content;
    padding: 12px 70px;
    border-radius: 50px;
    margin-top: 30px;
    transition: 0.2s;
    display: block;

    &:hover {
        transform: scale(1.03);
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
    }

    @media (max-width: ${MOBILE_460}px) {
        padding: 12px 50px;
    }
`

export const GridPanelContainer = styled.div`
    text-align: center;
    
    @media (max-width: ${TABLET_992}px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const GridPanel = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1500px;
    margin: auto;
    min-height: 300px;

    @media (max-width: ${TABLET_992}px) {
        width: 100%;
        max-width: 100%;
        grid-template-columns: 1fr;

    }
`;

export const GridPiture = styled.picture`
    grid-row-start: ${(props) => props.reverse ? "1" : "auto"};
    & > img, 
    & > source {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: ${TABLET_992}px) {
        grid-row-start: 1;
    }
`;

export const GridTextContainer = styled.div`
    grid-row-start: ${(props) => props.reverse ? "0" : "auto"};
    background: transparent linear-gradient(270deg, #E01A73 0%, #E0281A 100%) 0% 0% no-repeat padding-box;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 60px;
    text-align: left;

    @media (max-width: ${TABLET_992}px) {
        padding: 40px 5%;
    }
    @media (max-width: ${MOBILE_460}px) {
        padding-left: 0;
        padding-right: 0;
        & > * {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

export const GridTitle = styled.h3`
    position: relative;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 20px;

    &::before {
        content: "";
        width: 120px;
        height: 4px;
        background: white;
        position: absolute;
        bottom: -15px;
        left: 0;
    }
    @media (min-width: ${DESKTOP_1200}px) {
        font-size: 2.8rem;
        line-height: 3.5rem;
    }
    @media (max-width: ${TABLET_992}px) {
        font-size: 40px;
        line-height: 46px;
        padding-right: 4rem;
    }
    @media (max-width: ${TABLET_768}px) {
        font-size: 30px;
        line-height: 39px;
    }
    @media (max-width: ${MOBILE_460}px) {
        font-size: 30px;
        line-height: 39px;
    }
    @media (max-width: 400px) {
        font-size: 28px;
    }
    @media (max-width: ${MOBILE_380}px) {
        font-size: 26px;
    }
`;

export const DropDownArrow = styled.img`
    position: absolute;
    right: -30px;
    top: 0;
    display: none;

    @media (max-width: ${TABLET_768}px) {
        display: inline-block;
        transition: 0.5s ease-in-out;
        transform-origin: center;
        transform: translate(-100%, 50%) rotate( ${(props) => props.isActive ? '-180deg' : '0deg' });
    }
`;

export const GridList = styled.div`
    display: block;
    font-size: 1rem;
    line-height: 1.6rem;

    @media (max-width: ${TABLET_768}px) {
        transition: 0.5s ease-in-out;
        overflow: hidden;
        max-height: ${(props) => props.isActive ? '1000px' : '0px'};
    }
`;

export const GridListElement = styled.p`
    display: grid;
    grid-template-columns: 1em 100%;
    margin-bottom: 13px;
    width: 95%;
    font-size: 18px;

    &:first-of-type{
        padding-top: 20px;
    }

    &::before {
        content: "•";
        margin-right: 1em;
        color: ${COLORS.white};
        vertical-align: middle;
        font-size: 22px;
        line-height: 24px;
    }

    @media (max-width: ${MOBILE_460}px) {
        font-size: 16px;
    }
`;

export const GridListParagraph = styled.p`
    margin: 20px 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    
    @media (max-width: ${MOBILE_460}px) {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }
`

export const ApplyNowBackground = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto 0;
    padding: 60px;
    background: url(${apply_bg});
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: ${TABLET_992}px) {
        padding: 40px 5%;
    }
    @media (max-width: ${MOBILE_460}px) {
        text-align: left;
    }
`;

export const ApplyNowContainer = styled.div`
    width: 100%;
    margin: auto;

    @media (max-width: ${DESKTOP_1200}px) {
        width: 100%;
    }
`;

export const ApplyNowTitle = styled.div`
    color: ${COLORS.redTitle};
    position: relative;
    font-size: 2rem;
    line-height: 3rem;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 20px;
    
    &::before {
        content: "";
        width: 120px;
        height: 4px;
        background: ${COLORS.redTitle};
        position: absolute;
        bottom: -10px;
        left: 0;
    }
    
    @media (min-width: ${DESKTOP_1200}px) {
        font-size: 2.8rem;
        line-height: 3.5rem;
    }
    @media (max-width: ${TABLET_992}px) {
        font-size: 40px;
        line-height: 46px;
    }
    @media (max-width: ${MOBILE_460}px) {
        font-size: 30px;
        line-height: 39px;
    }
`;

export const ApplyTextContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    font-size: 19px;
    line-height: 28px;
    color: #464748;

    @media (max-width: ${TABLET_768}px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        transition: 0.5s ease-in-out;
        overflow: hidden;
        max-height: ${(props) => props.isActive ? '500px' : '0'};
    }
`;

export const ApplyNowSubtitleDesktop = styled.div`
    margin: 10px 0 10px;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #464748;   

    @media (max-width: ${MOBILE_460}px) {
        display: none;
    }
`;
export const ApplyNowSubTitleMobile = styled.div`
    display: none;

    @media (max-width: ${MOBILE_460}px) {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin: 32px 0 10px;
        color: #464748;
        display: block;
    }
`;

export const ApplyNowText = styled.div`
    padding-top: 5px;
    padding-bottom: 15px;
    max-width: 450px;
`;

export const ContactInfo = styled.ul`
    padding: 5px 20px;
    grid-row-end: span 2;

    @media (max-width: ${TABLET_768}px) {
       grid-row-end: span 1;
       padding: 5px 0;
    }
`;

export const ContactElement = styled.li`
    margin: 0 0 15px 30px;
    font: normal 18px/20px 'Roboto';
    list-style-type: disc;
    list-style-position: outside;

    &.location {
        list-style-image: url(${mapPin});
    }
    &.phone {
        list-style-image: url(${telephone});
    }
    &.mail {
        list-style-image: url(${mail});
    }
`;

export const GridText = styled.p`
    font-size: 1rem;
    line-height: 1.75rem;
`;

export const BenefitsContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 60px;
    background: white;
    text-align: left;
    max-width: 1500px;

    @media (max-width: ${TABLET_992}px) {
        padding: 40px 5%;
    }
    @media (max-width: ${TABLET_768}px) {
        transition: 0.5s ease-in-out;
        overflow: hidden;
        max-height: ${(props) => props.isActive ? '1000px' : '0px'};
        padding-bottom: ${(props) => props.isActive ? '50px' : '0px'};
    }
`;

export const Benefits = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0;
    display: flex;
    justify-content: left;
    flex-direction: column;

    @media (max-width: ${TABLET_768}px) {
        flex-direction: column;
        padding: 0;
    }
`;

export const TitleContainer = styled.div`
    display: block;
    width: fit-content;
`;

export const BenefitsTitle = styled.h3`
    position: relative;
    color: ${COLORS.redTitle};
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 20px;

    &::before {
        content: "";
        width: 120px;
        height: 4px;
        background: ${COLORS.redTitle};
        position: absolute;
        bottom: -15px;
        left: 0;
    }

    @media (min-width: ${DESKTOP_1200}px) {
        font-size: 2.8rem;
        line-height: 3.5rem;
    }
    @media (max-width: ${TABLET_992}px) {
        display: inline-block;
        font-size: 40px;
        line-height: 46px;
    }
    @media (max-width: ${MOBILE_460}px) {
        margin-left: 0;
        font-size: 30px;
        line-height: 39px;
    }
`;

export const BenefitsList = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-gap: 20px 30px;
    margin-top: 30px;

    @media (max-width: ${DESKTOP_1024}px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${TABLET_768}px) {
        margin-top: 22px;
        margin-left: 0;
        grid-gap: 20px 15px;
    }
    @media (max-width: ${TABLET_740}px) {
        grid-template-columns: 1fr;
    }
`
export const BenefitElement = styled.p`
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    color: ${COLORS.grayText};
    display: grid;
    grid-template-columns: 1em 100%;
    width: calc(100% - 30px);

    &::before {
        content: "•";
        color: ${COLORS.redTitle};
        margin-right: 1em;
        vertical-align: middle;
        font-size: 22px;
        line-height: 24px;
    }

    @media (max-width: ${TABLET_768}px) {
        width: calc(100% - 15px);
    }
    @media (max-width: ${MOBILE_460}px) {
        font-size: 16px;
    }
`;

export const FormBackground = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto 0;
    background: transparent linear-gradient(270deg, #E01A73 0%, #E0281A 100%) 0% 0% no-repeat padding-box;
    text-align: center;
    padding: 60px 0;

    @media (max-width: ${TABLET_768}px) {
        overflow: hidden;
        transition: 0.7s ease-in-out;
        max-height: ${props => props.isActive ? '2000px' : '0'};
        padding: ${props => props.isActive ? '60px 0' : '0'};
    }
`;

export const FormContainer = styled.form`
    width: 90%;
    margin: auto;
    text-align: center;
`;

export const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6rem;
    width: calc(100% - 2rem);
    margin: auto;

    @media (max-width: ${DESKTOP_1024}px) {
        grid-gap: 2.75rem;
    }
    @media (max-width: ${TABLET_768}px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
    
`;

export const FormGroup = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FormInputError = styled.div`
    top: 2px;
    position: relative;
    font-size: 0.75rem;
    color: ${COLORS.graySection};
    text-align: ${props => props.radio ? "left" : "right"};
    overflow: vissible;
    height: ${props => props.radio ? "0" : "1rem"};
    transform: ${props => props.radio ? "translateY(-0.5rem)" : ""} ${props => props.scheduleDays ? "translateX(100%)" : ""};
    max-width: ${props => ((props.scheduleHours || props.scheduleDays) && "50%")};
    padding-right:  ${props => ((props.scheduleHours || props.scheduleDays) && "0.75em")};
    & > div {
        width: 230px;
        text-align: center;
        position: relative;
        top: 5px;
    }
`

export const FormInputLabel = styled.label`
    color: white;
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
    line-height: 22px;

    @media (max-width: ${TABLET_768}px) {
        margin-top: 1rem;
    }
`;

export const FormInput = styled.input`
    appearance: none;
    display: block;
    // margin-bottom: 1.25rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid white;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;

    ::placeholder {
        color: white;
    }

`;

export const FormSelect = styled.button`
    appearance: none;
    display: block;
    // margin-bottom: 1.25rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid white;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    text-align:left;
    position: relative;

    transition: 0.3s ease-in-out;
    box-shadow: ${(props) => props.isActive ? '0 6px 5px rgba(0,0,0,0.15)' : 'none'} ;

    & :after {
        content: "";
        position: absolute;
        width: 12px; 
        height: 12px; 
        top: calc(50% - 4px);
        right: 12px;

        border-left: 1px solid white;
        // border-right: 6px solid transparent;
        border-bottom: 1px solid white;

        transform-origin: center; 
        transform:  translateY(-5px) rotate(-45deg);
    }
`;

export const SelectListContainer = styled.div`
    position: absolute;
    top: calc(100% + 1px);
    left: -1px;
    width: calc(100% + 2px);
    // background: #ea6572;
    // background: #FFFFFF5F 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(270deg, #E01A73 0%, #E0281A 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0 6px 5px rgba(0,0,0,0.15);
    z-index: 3;

    overflow-y: scroll;
    transition: 0.3s ease-in-out;
    height: ${(props) => props.isActive ? '100px' : '0px'};
    border-bottom: ${(props) => props.isActive ? '1px' : '0px'} solid white ;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: white;
    }
`;

export const SelectOption = styled.div`
    appearance: none;
    width: 100%;
    display: block;
    padding: 10px 20px;
    margin-top: -1px;
    background: rgba(255,255,255,0.15);
    color: white;
    border: 1px solid white;
    font-size: 1rem;
    
    z-index: 3;

    transition: 0.3s ease-in-out;
    &:hover {
        background: ${COLORS.redTitle};
    }
`;


export const FormFieldset = styled.fieldset`
    margin: ${(props) => props.margin ? props.margin : '1.25rem 0 2rem 0'};
    vertical-align: top;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 1rem;
    height: 100%;

    @media (max-width: ${DESKTOP_1024}px) {
        grid-gap: 0 0.45rem;
    }

    @media (max-width: ${TABLET_768}px) {
        margin: ${(props) => props.marginMobile && props.marginMobile};
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem 2rem;
    }
`;

export const FormRadioLabel = styled.label`
    vertical-align: top;
    color: white;
    display: grid;
    grid-template-columns: 1fr 99fr;
    font-size: 1rem;
    line-height: 1.2rem;
`;

export const FormRadio = styled.input`
    appearance: none;
    background-color: transparent;
    margin: 0;
    border: 1px solid white;
    border-radius: 50%;
    background: transparent;
    width: 1rem;
    height: 1rem;
    margin-right: 1em;
    display: inline-grid;
    place-content: center;

    @media (max-width: ${DESKTOP_1024}px) {
        margin-right: 0.3em;
    }

    @media (max-width: ${DESKTOP_1024}px) {
        margin-right: 0.65em;
    }

    & ::before {
        content: "";
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
    }

    & :checked::before  {
        transform: scale(1);
    }
`;

export const ButtonAndMessage = styled.div`
    @media (max-width: ${TABLET_768}px) {
        margin: 0 auto;
    }
`;

export const SubmitButton = styled.button`
    background: white;
    color: ${COLORS.redTitle};
    border: none;
    border-radius: 2em;
    padding: 10px 50px;
    width: fit-content;

    transition: 0.3s;
    &:hover {
        transform: scale(1.02);
        box-shadow: 0 0 7px rgba(0,0,0,0.3);
    }
`;

// Learn More Panel

export const LearnMoreContainer = styled.div`
    background-color: ${COLORS.white};
    display: flex;
    flex-direction: row;
    padding: 70px 0;
    background-size: cover;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    @media (max-width: ${DESKTOP_1200}px) {
        justify-content: space-around;
    }
    @media (max-width: ${TABLET_992}px) {
        padding: 40px 0;
        flex-direction: column;
        align-items: center;
    }
`;

export const PhoneIcon = styled.img`
    width: 50px;
    align-self: center;

    @media (max-width: ${TABLET_992}px) {
        width: 40px;
        align-self: center;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: 25px;
    }
`;

export const LearnMoreText = styled.div`
    margin-left: 20px;
    font: 400 36px/40px 'Roboto';
    color: ${COLORS.vermilion};
    display: flex;
    flex-direction: column;

    @media (max-width: ${TABLET_992}px) {
        font: 400 30px/40px 'Roboto';
    }
    @media (max-width: ${TABLET_740}px) {
        width: 80%;
    }
    @media (max-width: ${MOBILE_460}px) {
        margin-left: 10px;
        font: 400 6.5vw/7vw 'Roboto';
    }
`;

export const LearnMoreDiv = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${DESKTOP_1200}px) {
        justify-content: space-evenly;
    }
    @media (max-width: ${TABLET_992}px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const TextSection = styled.div`
    width: 45%;
    display: flex;

    @media (max-width: ${TABLET_992}px) {
        width: 450px;
        justify-content: center;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: auto;
    }
`;

export const LearnMoreButton = styled.a`
    padding: 12px 100px;
    align-self: center;
    font: 400 18px/24px 'Roboto';
    background-color: ${COLORS.vermilion};
    color: ${COLORS.white};
    border-radius: 24px;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;
    display: ${(props) =>  props.mobile ? "none" : "flex"};

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
    
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: ${DESKTOP_1024}px) {
        padding: 10px 60px;
        margin: 0;
    }
    @media (max-width: ${TABLET_992}px) {
        margin-top: 20px;
    }
    @media (max-width: ${TABLET_800}px) {
        padding: 10px 80px;
    }
    @media (max-width: ${MOBILE_460}px) {
        margin-top: 10px;
        padding: 10px 70px;
        font: 400 3.5vw/4vw 'Roboto';
        display: ${(props) =>  props.mobile ? "flex" : "none"};
    }
`;
